import React, {forwardRef} from 'react';
import Nav from 'react-bootstrap/Nav';
import {extendUrlWithAffiliateCode} from '../../util/utils.js';
// import './assets/styles/main.sass'

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Button = forwardRef((props, ref) => {
    let bClasses = ['button-main'];
    let wClasses = ['button-main-wrap'];

    props.styles?.forEach(e => {
        bClasses.push('button-main--' + e);
        wClasses.push('button-main-wrap--' + e);
    });

    // open external links in new tab
    const args = {};
    if (props.external) {
        args.href = extendUrlWithAffiliateCode(props.url);
        args.target = '_blank';
        args.rel = 'noreferrer';
    } else {
        args.href = props.url;
    }

    // return button only if a url is set
    return props.url !== '' ? (
        <Nav.Link className={wClasses.join(' ')} title={props.title} {...args}>
            <div ref={ref} className={bClasses.join(' ')}>
                {props.label}
            </div>
        </Nav.Link>
    ) : (<></>);
});

Button.displayName = 'Button';
export default Button