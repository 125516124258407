import { BASE } from '../../i18n/constants/locales';
import loadable from '@loadable/component'

// Every route - we lazy load so that each page can be chunked

const Dynamic = loadable(() => import('../../pages/Dynamic/Dynamic'));
const Home = loadable(() => import('../../pages/Home/Home'));
const NotFound = loadable(() => import('../../pages/NotFound/NotFound'));

export default [
    {
        path: BASE,
        component: Home,
        exact: true
    },
    {
        path: `${BASE}/:page`,
        component: Dynamic,
        exact: true
    },
    {
        path: '*',
        component: NotFound,
        status: 404
    },
];
