import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from '../i18n/i18n';
import { ApolloProvider } from '@apollo/client';
import { client } from '../services/client/client';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import routes from './routes';
import { FALLBACK } from '../i18n/constants/locales';

/**
 *
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
export const App = ({ history }) => {
    return (
        <Suspense>
            <I18nextProvider i18n={i18n}>
                <ApolloProvider client={client}>
                    <Router history={history}>
                        <Switch>
                            <Redirect exact from="/" to={FALLBACK} />
                            {routes.map((route) => (
                                <Route key={route.path} {...route} />
                            ))}
                        </Switch>
                    </Router>
                </ApolloProvider>
            </I18nextProvider>
        </Suspense>
    );
};

export default App;
