import React from 'react';
import { createBrowserHistory } from 'history';
import App from './app/App';
import './assets/styles/main.sass'
import * as ReactDOM from 'react-dom';
import {checkAffiliateCode, saveAffiliateCode} from './util/utils';

// eslint-disable-next-line no-unused-vars
import i18n from './i18n/i18n';

const history = createBrowserHistory();
const rootElement = document.getElementById('root');

checkAffiliateCode();
saveAffiliateCode();
ReactDOM.render(<App history={history} />, rootElement);