import { ApolloClient, InMemoryCache } from '@apollo/client';
import { preventVideoContextmenu } from '../../util/utils';

// eslint-disable-next-line no-undef
const endpoint = process.env.REACT_APP_TLL_API_ENDPOINT;
// eslint-disable-next-line no-undef
const apiToken = process.env.REACT_APP_TLL_API_TOKEN;

preventVideoContextmenu();

/**
 *
 * @type {ApolloClient<NormalizedCacheObject>}
 */
export const client = new ApolloClient({
    uri: endpoint,
    cache: new InMemoryCache(({
        typePolicies: {
            AccordionRecord: {
                keyFields: ['id','variantId']
            },
            ButtonRecord: {
                keyFields: ['id','label', 'linkExternal']
            },
            ContentRecord: {
                keyFields: ['id','variantId']
            },
            FaqRecord: {
                keyFields: ['id','question']
            },
            LabelRecord: {
                keyFields: ['id','label'],
            },
            LayoutRecord: {
                keyFields: ['id','headline','subline','appStoreGooglePlay',['headline']]
            },
            NavigationRecord: {
                keyFields: ['id','name','externalUrl']
            },
            NewsletterRegistrationRecord: {
                keyFields: ['id','variantId']
            },
            PageRecord: {
                keyFields: ['id','name','url'],
            },
            Site: {
                keyFields: ['globalSeo', ['fallbackSeo', ['title']]]
            },
        },
    })),
    headers: {
        authorization: apiToken,
    }
});