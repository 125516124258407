export const LOCALES =   [
    {
        code: 'de',
        name: 'Deutsch',
    },
    {
        code: 'en',
        name: 'English',
    },
    {
        code: 'fr',
        name: 'Français',
    }
]

export const BASE = '/:lang([de|en|fr]{2})';

export const FALLBACK = '/de';
