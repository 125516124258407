import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import loadable from '@loadable/component';
import parse, {domToReact} from 'html-react-parser';
import Button from '../components/elements/Button';

/**
 * ParseFromString
 * @param input
 * @returns {Document}
 */
export const domParser = (input) => {
    let parser = new DOMParser();
    return parser.parseFromString(input, 'text/html');
};

/**
 * Strip tags
 * @param input
 * @returns {*|string}
 */
export const stripTags = input => {
    let div = document.createElement('div');
    div.innerHTML = input;
    return div.textContent || div.innerText || '';
};

export const renderModules = input => {
    return input.map((item, index) => {
        let Module;
        let args;

        if (item.__typename === 'ContentPlacementRecord') {
            Module = loadable(() => import('../components/modules/' + item.template.widget));
            args = input[index].content[0];

            if (item.template.widget === 'PartnerAmbassadorComparison') {
                args = input[index].content;
            }
        } else if (item.__typename === 'AccordionPlacementRecord') {
            Module = loadable(() => import('../components/modules/AccordionModule'));
            args = input[index].accordion;

        } else if (item.__typename === 'NewsletterPlacementRecord') {
            Module = loadable(() => import('../components/modules/NewsletterModule'));
            args = input[index].newsletterRegistration;

        } else if (item.__typename === 'ContactFormPlacementRecord') {
            Module = loadable(() => import('../components/modules/ContactFormModule'));
            args = input[index].contactForm;
        }

        return (
            <Module key={index} {...args} />
        );
    });
};

/**
 * Check if ref on screen or not
 * @param ref
 * @returns {boolean}
 * @constructor
 */
export const useOnScreen = (ref) => {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
    );

    useEffect(() => {
        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, []);

    return isIntersecting;
};

export const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

// wrap children of li elements with span element, because otherwise
// display: flex breaks things (needed for the custom li icon)
export const parseWithLiWrap = html => {
    return parse(html, {
        replace: domNode => {
            if (domNode.type === 'tag' && domNode.name === 'li') {
                return <li><span>{domToReact(domNode.children)}</span></li>
            }
        }
    });
};

export const modifyHTMLWithAffiliateCode = html => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    tempDiv.querySelectorAll('a').forEach((link) => {
        const url = extendUrlWithAffiliateCode(link.href, );

        if(url !== ''){
            link.href = url;
        } else {
            // remove link from html if the url is empty
            // this occurs when no affiliate-code is present for the signup-url
            link.parentElement.classList.add('cta-wrap');
            link.remove();
        }
    });

    return tempDiv.innerHTML;
}

export const relativeUrlToAbsolute = segment => {
    const urlParts = window.location.href.split('/',4);
    const baseUrlWithLang = urlParts.join('/');
    return baseUrlWithLang + '/' + segment;
};

export const resolveInternalLink = linkItem => {
    const lang = window.location.href.split('/', 4)[3];
    if (linkItem && linkItem.isHomepage) {
        return '/' + lang;
    } else {
        return '/' + lang + '/' + linkItem?.url
    }
};

export const processUrl = (item) => {
    let link = '';
    if(item.linkExternal){
        // add affiliateCode to login or signup-url if existent
        link = extendUrlWithAffiliateCode(item.linkExternal, item.qaLink, item.stagingLink);
    } else {
        link = resolveInternalLink(item.linkInternal);
    }
    return link;
}

export const renderButtons = (buttonList, style = []) => {
    return buttonList.map((item, key) => {
        return (
            <Button key={key} styles={style} url={processUrl(item)} label={item.label} title={item.title} external={!!item.linkExternal} />
        )
    });
};


export const checkAffiliateCode = () => {
    // check localStorage for an affiliateCode
    if(localStorage.getItem('affiliateCode') !== null){
        const resInMillisecond = new Date().getTime() - JSON.parse(localStorage.getItem('affiliateCode')).timestamp;
        const resInDays = (resInMillisecond / 1000) / 86400;

        // clear affiliateCode if it is older then 365 days
        if(resInDays > 365){
            localStorage.removeItem('affiliateCode');
        }
    }
}

export const saveAffiliateCode = () => {
    const urlParams = window.location.search;
    // check if the url has a parameter link (affiliateCode)
    if(urlParams !== '' && urlParams.includes('?link=') || urlParams !== '' && urlParams.includes('&link=')){
        // if true, save the code in the localStorage, no matter if an affiliateCode already exists
        const urlKeyValues = urlParams.replace('?', '').split('&');
        for(let i = 0; i < urlKeyValues.length; i++){
            const keyValue = urlKeyValues[i].split('=');
            if(keyValue[0] === 'link'){
                localStorage.setItem('affiliateCode',JSON.stringify({code: keyValue[1], timestamp: new Date().getTime()}));
            }
        }
    }
}

// link = production and live
export const extendUrlWithAffiliateCode = (origLink, qaLink = '', stagingLink = '') => {
    let link = origLink;
    if(localStorage.getItem('affiliateCode') !== null){
        if(link.includes('/signup') && typeof window !== 'undefined'){
            const hostname = window.location.hostname;
            // prefix url and choose link depending on current environment
            if (window._partnerLinks && window._partnerLinks.hasOwnProperty(hostname)) {
                const affiliateCode = JSON.parse(localStorage.getItem('affiliateCode')).code;
                link = window._partnerLinks[hostname].includes('CODE')
                    ? window._partnerLinks[hostname].replace('CODE', affiliateCode)
                    : `${window._partnerLinks[hostname]}/${affiliateCode}`.replace(`//${affiliateCode}`, `/${affiliateCode}`);             
            }
        }
    } else {
        // return empty string for the signup-url when no affiliate-code is present
        // this will prevent the button from rendering (see Button.js)
        if(link.includes('/signup')){
            link = '';
        }
    }

    return link;
}

// prevent contextmenu on videos to hide download option
export const preventVideoContextmenu = () => {
    document.addEventListener('contextmenu', (e) => {
        if (e.target.nodeName.toLowerCase() === 'video') {
            e.preventDefault();
        }
    });
}

export const setPartnerLinks = (str) => {
    const regex = /<li>(.*?)<\/li>/ig;
    const matches = str.match(regex);
    const partnerLinks = {};
    for (const match of matches) {
        const item = match.slice(4,-5).split('|');        
        const el = document.createElement('div');
        el.innerHTML = item[1];
        partnerLinks[item[0]] = el.textContent;
    }
    window._partnerLinks = partnerLinks;
}

export const setUrlLoginLinks = (str) => {
    const regex = /<li>(.*?)<\/li>/ig;
    const matches = str.match(regex);
    const urlLoginLinks = {};
    for (const match of matches) {
        const item = match.slice(4,-5).split('|');        
        const el = document.createElement('div');
        el.innerHTML = item[1];
        urlLoginLinks[item[0]] = el.textContent;
    }
    window._urlLoginLinks = urlLoginLinks;
}

export const getUrlLoginLink = ({name, externalUrl}) => {
    let url = externalUrl;
    if (name === 'Login' && typeof window !== 'undefined') {
        const hostname = window.location.hostname;
        // prefix url and choose link depending on current environment
        if (window._urlLoginLinks && window._urlLoginLinks.hasOwnProperty(hostname)) {
            url = window._urlLoginLinks[hostname];
        }
    }
    return url;
}